import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/en.json'
import ru from './ru/ru.json'

export const defaultNS = 'ru';

i18next.use(initReactI18next).init({
  lng: 'ru',
  resources: {
    en: {
      en
    },
    ru: {
      ru
    }
  },
  defaultNS,
});