import styled from "styled-components";

export const CodeInput = styled.input`
  min-width: 63.3333333px;
  border: 1.5px solid rgba(245, 245, 245, 0.04);
  color: #F5F5F5;
  font-family: Gravity;
  font-size: 20px;
  height: 55px;
  border-radius: 4px;
  background-color: rgba(245, 245, 245, 0.04);
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
  padding: 0 20px;
`