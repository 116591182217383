import styled from "styled-components";

export const DropdownOptionsWrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  border-radius: 4px;
  border: 1.5px solid var(--transparent-white-20, rgba(245, 245, 245, 0.20));
  background: #646464;
  z-index: 1;
  padding: 16px 20px;
  box-sizing: border-box;  
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

